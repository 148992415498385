.splash-wrapper {
  height: calc(100vh - (var(--header-height)));
  width: auto;
}

.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #baddda;
}

.svg--reduce-size {
  height: 82vh;
  width: 80vw;
}

#svg-oldtree * {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}

#svg-oldtree.finished * {
  fill-opacity: 1;
  fill: #322f1d;
}
