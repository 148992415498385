:root {
  --side-padding: 0.5rem;
}

.flip-wrapper {
  width: 12rem;
  height: 15rem;
  line-height: 1.5;
  margin: 1rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-size: medium;
  cursor: pointer;
  background-color: transparent;
  perspective: 1000px;
  z-index: 10;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.card--flipped .flip-wrapper-inner {
  transform: rotateY(180deg);
}

.flip-wrapper-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: calc(100% - (var(--side-padding) * 2));
  height: 100%;
  padding-left: var(--side-padding);
  padding-right: var(--side-padding);
  background-color: #f9f3db;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  /*
  Removing this introduces bug where only left portion, 
  50% and under of inquiry button is clickable.  Putting 
  it in introduces bug with Tilt and image under margin.  */

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.card-back-inset {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #f9f3db;
  margin-top: -1rem;
  z-index: 9;
}

.card-img-avatar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 4.5rem;
  width: 10rem;
  object-fit: cover;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  margin-top: -2rem;
  z-index: 2;
}

.card-img-avatar-cover {
  background-color: #4a4737;
  z-index: -1;
  color: #f9f3db;
  border-color: black;
}

.card-large-img {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  z-index: 2;
  position: absolute;
  bottom: 0.5rem;
  margin-left: -4rem;
}

.card-front-details {
  margin-top: 3.5rem;
  text-align: center;
}

.card-front-details p,
h2 {
  margin: 0;
}

.card-back-details {
  margin-top: 1rem;
  text-align: center;
}

.card-front hr,
.card-back hr {
  margin-right: auto;
  margin-left: auto;
  max-width: 7rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-width: 0.125rem;
  border-color: rgba(0, 0, 0, 0.4);
}

.card-back {
  transform: rotateY(180deg);
}

.card-name-back {
  margin-top: 0.5rem;
}

button {
  background-color: #4a4737;
  color: #f9f3db;
  padding: 0.2rem;
}

.card-button {
  margin-right: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
}

#rabbit-details {
  padding-top: 0.25rem;
}
