@import url("https://fonts.googleapis.com/css2?family=Ballet&family=Benne&display=swap");

:root {
  font-size: calc(1em + 0.5vw);
  --header-height: 3em;
  --header-border-width: 2px;
  --footer-height: 7em;
  --content-spacer: 1em;
  --sidebar-width: 0;
}

html,
body {
  margin: auto 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background-color: #322f1d;
  font-family: "Benne", serif;
  overflow: hidden;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  scroll-behavior: smooth;
  /*
  font-size-adjust: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  font-size: calc(1em + 1vw)
  font-size: calc(20px + 6 * ((100vw - 320px) / 680));
*/
}

#parent {
  height: 100%;
  width: 100%;
  background-color: #baddda;
}

main {
  margin-left: 0;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  z-index: 1;
  /* overflow-y: auto; */
}

header {
  position: fixed;
  top: 0;
  height: var(--header-height);
  width: 100%;
  margin-left: 0px;
  border: #4a4737;
  border-width: var(--header-border-width);
  border-style: dashed;
  background-color: #b0ab99;
  overflow: hidden;
  z-index: 5;
}

.header-desktop {
  display: none;
}

.header-nav-wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
}

header li {
  flex-wrap: nowrap;
  list-style-type: none;
  padding-left: 1.5em;
}

.push_right {
  position: absolute;
  right: calc(
    var(--sidebar-width) + var(--content-spacer) + var(--content-spacer)
  );
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: var(--sidebar-width);
  border: #4a4737;
  border-width: 2px;
  border-style: dashed;
  background-color: #b0ab99;
  z-index: 10;
  overflow-y: auto;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  text-decoration: none;
  display: none;
}

#sidebar-desktop {
  display: none;
}

#sidebar-logo-large-wrapper {
  background-color: #4a4737;
  width: 7rem;
  height: 7rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  color: #f9f3db;
}

#sidebar-logo-large {
  height: 4.5em;
  margin-top: var(--content-spacer);
  font-family: "Ballet", cursive;
  font-size: 1.5rem;
  transition: all 3s ease;
  text-align: center;
}

#sidebar-logo-small-wrapper {
  background-color: #4a4737;
  width: 105%;
  margin-left: -2px;
  border-radius: 5px;
  color: #f9f3db;
}

#sidebar-logo-small {
  height: 4em;
  margin-top: var(--content-spacer);
  font-family: "Ballet", cursive;
  transition: all 2s ease;
  text-align: center;
}

footer {
  position: relative;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  margin-left: 0px;
  padding-left: var(--content-spacer);
  background-color: #baddda;
  color: #4a4737;
  overflow: hidden;
}

.child_container {
  min-height: 2.5em;
  min-width: 15em;
  max-width: 20em;
  max-height: 5em;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  background-color: #f9f3db;
  margin: 5px;
  padding-left: var(--content-spacer);
  filter: drop-shadow(3px 1px 3px #636161);
}

.scroll-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: center;
  height: calc(100vh - var(--header-height));
  min-height: calc(100vh - var(--header-height));
  overflow-y: auto;
}

.margin-zero {
  margin: 0;
}

.flex_container {
  display: flex;
}

.flex_row {
  flex-direction: row;
  flex-wrap: wrap;
}

.flex_column {
  flex-direction: column;
  flex-wrap: wrap;
}

.flex_center {
  align-items: center;
  justify-content: center;
}

.flex_center_vertical {
  align-items: center;
}

.div-info {
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  background-color: #f9f3db;
  max-width: 60em;
  margin: 1rem;
}

.drop-shadow {
  /* for transparent images ?*/
  filter: drop-shadow(3px 6px 9px #636161);
}

.stitching {
  outline: 1px dashed #000000;
  outline-offset: -7px;
}

.stitching-inverse {
  outline: 1px dashed #f9f3db;
  outline-offset: -7px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.04);
}

.spacer {
  height: calc(var(--content-spacer) * 2);
}

.header-hidden-spacer {
  visibility: hidden;
  height: calc(var(--header-height) + (var(--header-border-width) * 2));
}

@media screen and (min-width: 40em) {
  :root {
    --header-height: 2rem;
    --content-spacer: 0.5rem;
    --sidebar-width: 5.5rem;
  }
  header {
    margin-left: var(--sidebar-width);
    padding-left: var(--content-spacer);
  }
  .header-mobile {
    display: none;
  }
  .header-desktop {
    display: block;
  }
  .sidebar {
    display: block;
    overflow: hidden;
  }
  #sidebar-tablet {
    display: block;
    font-size: 1em;
  }
  main {
    margin-left: var(--sidebar-width);
  }
  .scroll-wrapper {
    height: calc(100vh - var(--header-height));
    min-height: calc(100vh - var(--header-height));
  }
  .child_container {
    min-height: 4.5em;
  }
  footer {
    height: calc(var(--footer-height) - 2em);
  }
}

@media screen and (min-width: 64em) {
  :root {
    font-size: 1.3em;
    --sidebar-width: 8rem;
  }
  #sidebar-tablet {
    display: none;
  }
  #sidebar-desktop {
    display: block;
  }
  footer {
    height: calc(var(--footer-height) - 3em);
  }
}
