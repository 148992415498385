@import url(https://fonts.googleapis.com/css2?family=Ballet&family=Benne&display=swap);
:root {
  font-size: calc(1em + 0.5vw);
  --header-height: 3em;
  --header-border-width: 2px;
  --footer-height: 7em;
  --content-spacer: 1em;
  --sidebar-width: 0;
}

html,
body {
  margin: auto 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  background-color: #322f1d;
  font-family: "Benne", serif;
  overflow: hidden;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  scroll-behavior: smooth;
  /*
  font-size-adjust: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  font-size: calc(1em + 1vw)
  font-size: calc(20px + 6 * ((100vw - 320px) / 680));
*/
}

#parent {
  height: 100%;
  width: 100%;
  background-color: #baddda;
}

main {
  margin-left: 0;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 1;
  /* overflow-y: auto; */
}

header {
  position: fixed;
  top: 0;
  height: 3em;
  height: var(--header-height);
  width: 100%;
  margin-left: 0px;
  border: #4a4737;
  border-width: 2px;
  border-width: var(--header-border-width);
  border-style: dashed;
  background-color: #b0ab99;
  overflow: hidden;
  z-index: 5;
}

.header-desktop {
  display: none;
}

.header-nav-wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  height: 100%;
  width: 100%;
}

header li {
  flex-wrap: nowrap;
  list-style-type: none;
  padding-left: 1.5em;
}

.push_right {
  position: absolute;
  right: calc(
    0 + 1em + 1em);
  right: calc(
    var(--sidebar-width) + var(--content-spacer) + var(--content-spacer)
  );
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0;
  width: var(--sidebar-width);
  border: #4a4737;
  border-width: 2px;
  border-style: dashed;
  background-color: #b0ab99;
  z-index: 10;
  overflow-y: auto;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  text-decoration: none;
  display: none;
}

#sidebar-desktop {
  display: none;
}

#sidebar-logo-large-wrapper {
  background-color: #4a4737;
  width: 7rem;
  height: 7rem;
  margin-top: 0.5rem;
  border-radius: 5px;
  color: #f9f3db;
}

#sidebar-logo-large {
  height: 4.5em;
  margin-top: 1em;
  margin-top: var(--content-spacer);
  font-family: "Ballet", cursive;
  font-size: 1.5rem;
  transition: all 3s ease;
  text-align: center;
}

#sidebar-logo-small-wrapper {
  background-color: #4a4737;
  width: 105%;
  margin-left: -2px;
  border-radius: 5px;
  color: #f9f3db;
}

#sidebar-logo-small {
  height: 4em;
  margin-top: 1em;
  margin-top: var(--content-spacer);
  font-family: "Ballet", cursive;
  transition: all 2s ease;
  text-align: center;
}

footer {
  position: relative;
  bottom: 0;
  height: 7em;
  height: var(--footer-height);
  width: 100%;
  margin-left: 0px;
  padding-left: 1em;
  padding-left: var(--content-spacer);
  background-color: #baddda;
  color: #4a4737;
  overflow: hidden;
}

.child_container {
  min-height: 2.5em;
  min-width: 15em;
  max-width: 20em;
  max-height: 5em;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  background-color: #f9f3db;
  margin: 5px;
  padding-left: 1em;
  padding-left: var(--content-spacer);
  -webkit-filter: drop-shadow(3px 1px 3px #636161);
          filter: drop-shadow(3px 1px 3px #636161);
}

.scroll-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: center;
  height: calc(100vh - 3em);
  height: calc(100vh - var(--header-height));
  min-height: calc(100vh - 3em);
  min-height: calc(100vh - var(--header-height));
  overflow-y: auto;
}

.margin-zero {
  margin: 0;
}

.flex_container {
  display: flex;
}

.flex_row {
  flex-direction: row;
  flex-wrap: wrap;
}

.flex_column {
  flex-direction: column;
  flex-wrap: wrap;
}

.flex_center {
  align-items: center;
  justify-content: center;
}

.flex_center_vertical {
  align-items: center;
}

.div-info {
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  background-color: #f9f3db;
  max-width: 60em;
  margin: 1rem;
}

.drop-shadow {
  /* for transparent images ?*/
  -webkit-filter: drop-shadow(3px 6px 9px #636161);
          filter: drop-shadow(3px 6px 9px #636161);
}

.stitching {
  outline: 1px dashed #000000;
  outline-offset: -7px;
}

.stitching-inverse {
  outline: 1px dashed #f9f3db;
  outline-offset: -7px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.spacer {
  height: calc(1em * 2);
  height: calc(var(--content-spacer) * 2);
}

.header-hidden-spacer {
  visibility: hidden;
  height: calc(3em + (2px * 2));
  height: calc(var(--header-height) + (var(--header-border-width) * 2));
}

@media screen and (min-width: 40em) {
  :root {
    --header-height: 2rem;
    --content-spacer: 0.5rem;
    --sidebar-width: 5.5rem;
  }
  header {
    margin-left: 0;
    margin-left: var(--sidebar-width);
    padding-left: 1em;
    padding-left: var(--content-spacer);
  }
  .header-mobile {
    display: none;
  }
  .header-desktop {
    display: block;
  }
  .sidebar {
    display: block;
    overflow: hidden;
  }
  #sidebar-tablet {
    display: block;
    font-size: 1em;
  }
  main {
    margin-left: 0;
    margin-left: var(--sidebar-width);
  }
  .scroll-wrapper {
    height: calc(100vh - 3em);
    height: calc(100vh - var(--header-height));
    min-height: calc(100vh - 3em);
    min-height: calc(100vh - var(--header-height));
  }
  .child_container {
    min-height: 4.5em;
  }
  footer {
    height: calc(7em - 2em);
    height: calc(var(--footer-height) - 2em);
  }
}

@media screen and (min-width: 64em) {
  :root {
    font-size: 1.3em;
    --sidebar-width: 8rem;
  }
  #sidebar-tablet {
    display: none;
  }
  #sidebar-desktop {
    display: block;
  }
  footer {
    height: calc(7em - 3em);
    height: calc(var(--footer-height) - 3em);
  }
}

a {
  text-decoration: none;
  color: black;
}

h3 {
  text-align: center;
}

p {
  text-indent: 1em;
  margin-top: 0.5em;
}

.splash-wrapper {
  height: calc(100vh - (var(--header-height)));
  width: auto;
}

.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #baddda;
}

.svg--reduce-size {
  height: 82vh;
  width: 80vw;
}

#svg-oldtree * {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}

#svg-oldtree.finished * {
  fill-opacity: 1;
  fill: #322f1d;
}

:root {
  --side-padding: 0.5rem;
}

.flip-wrapper {
  width: 12rem;
  height: 15rem;
  line-height: 1.5;
  margin: 1rem;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  font-size: medium;
  cursor: pointer;
  background-color: transparent;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  z-index: 10;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.card--flipped .flip-wrapper-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-wrapper-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.card-front,
.card-back {
  position: absolute;
  width: calc(100% - (0.5rem * 2));
  width: calc(100% - (var(--side-padding) * 2));
  height: 100%;
  padding-left: 0.5rem;
  padding-left: var(--side-padding);
  padding-right: 0.5rem;
  padding-right: var(--side-padding);
  background-color: #f9f3db;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  /*
  Removing this introduces bug where only left portion, 
  50% and under of inquiry button is clickable.  Putting 
  it in introduces bug with Tilt and image under margin.  */

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.card-back-inset {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #f9f3db;
  margin-top: -1rem;
  z-index: 9;
}

.card-img-avatar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 4.5rem;
  width: 10rem;
  object-fit: cover;
  border-radius: 100%;
  border-style: solid;
  border-width: 1px;
  margin-top: -2rem;
  z-index: 2;
}

.card-img-avatar-cover {
  background-color: #4a4737;
  z-index: -1;
  color: #f9f3db;
  border-color: black;
}

.card-large-img {
  height: 8rem;
  width: 8rem;
  object-fit: cover;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  z-index: 2;
  position: absolute;
  bottom: 0.5rem;
  margin-left: -4rem;
}

.card-front-details {
  margin-top: 3.5rem;
  text-align: center;
}

.card-front-details p,
h2 {
  margin: 0;
}

.card-back-details {
  margin-top: 1rem;
  text-align: center;
}

.card-front hr,
.card-back hr {
  margin-right: auto;
  margin-left: auto;
  max-width: 7rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-width: 0.125rem;
  border-color: rgba(0, 0, 0, 0.4);
}

.card-back {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card-name-back {
  margin-top: 0.5rem;
}

button {
  background-color: #4a4737;
  color: #f9f3db;
  padding: 0.2rem;
}

.card-button {
  margin-right: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
}

#rabbit-details {
  padding-top: 0.25rem;
}

.div-info {
  padding: 1rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  border-color: #000000;
  background-color: #f9f3db;
}

.drop-shadow {
  /* for transparent images ?*/
  -webkit-filter: drop-shadow(3px 6px 9px #636161);
          filter: drop-shadow(3px 6px 9px #636161);
}

.stitching {
  outline: 1px dashed #000000;
  outline-offset: -7px;
}

input {
  height: 1.1rem;
}

textarea {
  width: 15rem;
}

form button {
  padding: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tilt-wrapper:hover .card-front,
.tilt-wrapper:hover .card-back {
  box-shadow: 4px 9px 12px 5px rgba(0, 0, 0, 0.3);
  z-index: 15;
}

.visible {
  opacity: 1;
  /* visibility: visible; */
  transition: 0.5s opacity, 0 0.5s;
}

